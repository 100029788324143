<template>
  <b-row>
    <b-col>
      <b-card title="Actualizar roles">
        <b-row>
          <b-col md="6">
            <div class="form-group">
              <label>Perfil</label>
              <input
                v-model="form.name"
                type="text"
                class="form-control form-control-sm"
                placeholder="Component"
              />
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <h5>Listado de permisos</h5>
            <div class="checkbox-inline">
              <label class="checkbox checkbox-outline checkbox-primary">
                <input
                  type="checkbox"
                  @click="selectAll()"
                  v-model="selected"
                />
                <span></span>Seleccionar todo</label
              >
            </div>
            <hr />
          </b-col>

          <b-col md="2" v-for="(item, idx) in permission" :key="idx">
            <div class="checkbox-inline">
              <label class="checkbox checkbox-outline checkbox-primary">
                <input
                  type="checkbox"
                  v-model="form.permissions"
                  :value="item.id"
                />
                <span></span>{{ item.guard_name }}</label
              >
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <div class="d-flex justify-content-end">
              <router-link
                :to="{ name: 'roles' }"
                class="btn btn-sm btn-circle btn-link mr-3"
              >
                <i class="la la-reply"></i> Regresar
              </router-link>
              <button
                class="btn btn-sm btn-circle btn-success"
                @click="update()"
                v-if="can('update-role')"
              >
                <i class="flaticon-edit-1"></i>
                {{ loading ? "Cargando..." : "Actualizar" }}
              </button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import axios from "axios";
import toastr from "toastr";
import { mapActions, mapGetters } from "vuex";
import can from "@/permission";
export default {
  data() {
    return {
      id: this.$route.params.id,
      show: false,
      loading: false,
      permission: [],
      form: {
        permissions: [],
        name: null,
        guard_name: null,
      },
      selected: false,
    };
  },
  mounted() {
    this.loadRole();
    this.loadPermission();
  },
  computed: {
    ...mapGetters({
      token: "auth/getToken",
    }),
  },
  methods: {
    ...mapActions({
      updateStatus: "auth/attempt",
    }),
    can: can,
    selectAll() {
      if (this.form.permissions.length !== this.permission.length) {
        this.form.permissions = this.permission.map((element) => {
          return element.id;
        });
      } else {
        this.form.permissions = [];
      }
    },
    loadPermission() {
      axios
        .get("permissions")
        .then((res) => {
          this.permission = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    loadRole() {
      axios
        .get(`roles/${this.id}`)
        .then((res) => {
          const [data] = res.data;
          this.form = data;
          this.form.permissions = this.form.permissions.map((f) => {
            return f.id;
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    update() {
      if (this.form.name) {
        this.loading = true;
        axios
          .put(`roles/${this.id}`, this.form)
          .then((res) => {
            if (res.data.message == "success") {
              this.show = false;
              this.loading = false;
              let timerInterval;
              this.$swal
                .fire({
                  title: "Información",
                  icon: "success",
                  html: "Registro actualizado, <b></b>",
                  timer: 800,
                  timerProgressBar: true,
                  didOpen: () => {
                    this.$swal.showLoading();
                    const b = this.$swal.getHtmlContainer().querySelector("b");
                    timerInterval = setInterval(() => {
                      b.textContent = this.$swal.getTimerLeft();
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  },
                })
                .then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === this.$swal.DismissReason.timer) {
                    this.loadRole();
                    this.updateStatus(this.token);
                  }
                });
            }
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
          });
      } else {
        toastr.info(`Todos los campos son obligatorios`);
      }
      //code
    },
  },
};
</script>
